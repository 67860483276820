import "slick-carousel";
import "slick-carousel/slick/slick.css";

$(document).ready(function () {
  $(".cs-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
    arrows: true,
    dots: true,
    prevArrow: $(".cs-arrow-prev"),
    nextArrow: $(".cs-arrow-next"),
    appendDots: $(".cs-dots"),
    fade: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  });
});
